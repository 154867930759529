import InfoPages from "../index";
import PageTitle from "../../../ui/PageTitle";
import MESSAGES from "../../../constants/messages";

const TermsAndCondition = () => {
  return (
    <InfoPages> <PageTitle title={MESSAGES.TERM_AND_CONDITION} />
      <p>The following terms and conditions apply to any orders submitted through <a href="http://ucr-us.com" className="important">ucr-us.com</a> (referred to as the "Company").</p>
      <p>By using the services of the Company, the customer (which includes brokers, freight forwarders, motor carriers and their authorized agents) agrees to be bound by these terms and conditions.</p>
      <p>The Company reserves the right to make changes to these terms and conditions at any time without notice. Furthermore, the company shall not be held liable for any loss due to unforeseen circumstances that may prevent the company from fulfilling the agreement.</p>
      <h3>Payments</h3>
      <p>Customers are required to pay when they submit an order online. This payment is non-refundable.</p>
      <p>The use of the same credit card for multiple payments is not allowed. Once a credit card has been charged, it cannot be used again for payment. To place another order for the same service, customers must use a different method of payment.</p>
      <p>The Company has the right to decline orders if a customer does not pay or is unwilling to make a payment. Additionally, the Company reserves the right to cancel any order for any reason at any time.</p>
      <p>The customer authorizes the Company to charge their valid credit or debit card on file for the payment and understands that this authorization will remain in effect until the payment is made in full.</p>
      <h3>Pricing Errors</h3>
      <p>In the event that a service is listed on the Company's website at an incorrect price due to a typographical or pricing error, the Company reserves the right to cancel or refuse any orders placed for that service at the inaccurate price.</p>
      <p>The Company has the right to cancel these orders, whether the order has been confirmed or not, and to also charge the customer's credit card. In case a customer's account has been charged for a purchase but the order is canceled, the Company will issue a credit to the customer's card for the amount of the incorrect fee.
      </p>
      <h3>Disclaimer</h3>
      <p>The process is considered complete and fulfilled once the Company representative sends the proof of a permit to the customer. It is the customer's responsibility to familiarize themselves with the information in the permit provided. The permit will be issued according to FMCSA standards based on the information provided by the customer.</p>

    </InfoPages>
  );
};

export default TermsAndCondition;
