import validate from "./validate";
import Input from "../../ui/Input";
import PropTypes from "prop-types";
import Modal from "../../ui/Modal";
import Button from "../../ui/Button";
import {useAlert} from "react-alert";
import Loading from "../../ui/Loading";
import {useParams} from "react-router";
import SelectYear from "./SelectYear";
import {useEffect, useState} from "react";
import Checkbox from "../../ui/Input/Checkbox";
import MESSAGES from "../../constants/messages";
import {editCarrierInfoById} from "../../Services";
import CompanyRequired from "./ModalContent/CompanyRequired";
import IntrastateNotice from "./ModalContent/IntrastateNotice";
import StepperFooter from "../shared/Stepper/components/StepperFooter";

import "./index.scss";

const delta = new Date().getMonth() > 8 ? 1 : 0;
const currentYear = new Date().getFullYear() + delta;
const years = Array(4).fill(currentYear).map((y, i) => y-i);
const CarrierInfo = ({ onPrev, onNext, id, state, saveState }) => {
  const alert = useAlert();
  const {usDot} = useParams();
  const [loading, setLoading] = useState();
  const [terms, setTerms] = useState(false);
  const [checked, setChecked] = useState(false);
  const [companyRequiredOpen, setCompanyRequiredOpen] = useState(false);
  const [intrastateNoticeOpen, setIntrastateNoticeOpen] = useState(false);
  const [errors, setErrors] = useState({
    hasError: false,
    errors: {}
  });
  const [isMobileSame, setIsMobileSame] = useState(false);
  const [data, setData] = useState({
    Id: id,
    Email: "",
    UsDot: usDot,
    FullName: "",
    MobilePhone: "",
    BusinessPhone: "",
    RegistrationYear: [currentYear],
    ...state
  });

  const onChange = (e, key) => {
    setData(prev=> ({
      ...prev,
      [key]: e.target.value,
      ...(isMobileSame && key === "BusinessPhone" ? {MobilePhone: e.target.value} : {})
    }));
  };

  useEffect(() => {
    if(errors.hasError) {
      setErrors(validate({
        ...data,
        checked,
        terms,
      }));
    }
  }, [checked, terms, errors.hasError, data]);

  const selectSameMobile = (e) => {
    setIsMobileSame(e.currentTarget.checked);
    if (e.currentTarget.checked) {
      setData(prev => ({
        ...prev,
        MobilePhone: prev.BusinessPhone
      }))
    }
  };

  const onSave = () => {
    const res = validate({
      ...data,
      checked,
      terms,
    });
    if (res.hasError) {
      setErrors(res);
      return;
    }

    setLoading(true);
    editCarrierInfoById(data.Id, data)
    .then(response => {
      if (response.hasError) {
        response.errors.map(({ errorMessage }) => alert.show(errorMessage, {type: "error"}));
      } else {
        saveState(data);
        onNext();
      }
    })
    .catch(err => {
      alert.show(err.message || err, {type: "error"});
    })
    .finally(() => setLoading(false));
  };

  const addNewYearRow = () => {
    setData(prev => ({
      ...prev,
      RegistrationYear: [
        ...prev.RegistrationYear,
        years.filter(y => prev.RegistrationYear.indexOf(y) === -1)[0]
      ]
    }));
  };

  const deleteYear = (index) => {
    setData(prev => ({
      ...prev,
      RegistrationYear: prev.RegistrationYear.filter((_, i) => i!== index)
    }));
  };

  const readMore = () => {
    setIntrastateNoticeOpen(false);
    setCompanyRequiredOpen(true);
  };

  const onChangeYear = (e, i) => {
    const RegistrationYear = [...data.RegistrationYear];
    RegistrationYear[i] = +e.target.value;

    setData(prev => ({
      ...prev,
      RegistrationYear
    }));
  };

  return (
    <div className="carrier-info-step">
      <div className="creator-input">
        <Input
          required
          action="outline"
          value={data.FullName}
          label={MESSAGES.WHO_IS_CREATING_THIS_REGISTRATION}
          error={errors.errors.FullName}
          placeholder={MESSAGES.FIRST_AND_LAST}
          onChange={e => onChange(e, "FullName")}
        />
      </div>
      <div className="read-more-block">
        <Checkbox
          name="terms"
          checked={terms}
          error={errors.errors.terms}
          label={MESSAGES.CARRIER_TERMS(usDot)}
          onChange={() =>  setTerms(prev => !prev)}
        />
      </div>
      <p className="group-input-label">{MESSAGES.INTRASTATE_NOTICE}</p>
      <div className="read-more-block">
        <Checkbox
          name="agree"
          checked={checked}
          error={errors.errors.checked}
          label={MESSAGES.I_AGREE_THAT_MY_BUSINESS_IS_SUBJECT_TO_UCR}
          onChange={() =>  setChecked(prev => !prev)}
        />
        <Button type="link" onClick={() => setIntrastateNoticeOpen(true)}>{MESSAGES.READ_MORE}</Button>
      </div>
      <SelectYear
        years={years}
        onChange={onChangeYear}
        deleteYear={deleteYear}
        addNewYearRow={addNewYearRow}
        selected={data.RegistrationYear}
        error={errors.errors.RegistrationYear}
      />
      <div>
        <Input
          required
          action="outline"
          value={data.Email}
          error={errors.errors.Email}
          label={MESSAGES.EMAIL_ADDRESS}
          placeholder="example@example.com"
          onChange={e => onChange(e, "Email")}
        />
        <Input
          required
          action="outline"
          value={data.BusinessPhone}
          label={MESSAGES.BUSINESS_PHONE}
          error={errors.errors.BusinessPhone}
          onChange={e => onChange(e, "BusinessPhone")}
          placeholder={MESSAGES.ENTER_YOUR_CONTACT_BUSINESS_PHONE}
        />
        <Input
          required
          action="outline"
          disabled={isMobileSame}
          value={data.MobilePhone}
          error={errors.errors.MobilePhone}
          onChange={e => onChange(e, "MobilePhone")}
          placeholder={MESSAGES.ENTER_YOUR_CONTACT_MOBILE_NUMBER}
          label={<>
            {MESSAGES.MOBILE}
            <span className="label-input">
              <input type="checkbox" onChange={selectSameMobile} checked={isMobileSame}/>
              {MESSAGES.CHECK_IF_MOBILE_NUMBER_IS_THE_SAME_AS_BUSINESS_PHONE}
            </span>
          </>}
        />
      </div>
      <StepperFooter
        onClickNext={onSave}
        onClickPrevious={onPrev}
        disabledNext={errors.hasError}
      />
      <Modal
        open={intrastateNoticeOpen}
        title={MESSAGES.INTRASTATE_NOTICE}
        onClose={() => setIntrastateNoticeOpen(false)}
      >
        <IntrastateNotice readMore={readMore} />
      </Modal>
      <Modal
        open={companyRequiredOpen}
        title={MESSAGES.COMPANY_REQUIRED_TITLE}
        onClose={() => setCompanyRequiredOpen(false)}
      >
        <CompanyRequired />
      </Modal>
      <Loading isLoading={loading} />
    </div>
  );
};

CarrierInfo.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  saveState: PropTypes.func.isRequired,
};

export default CarrierInfo;
