import DATA from "./constants";
import PageTitle from "../../../../ui/PageTitle";

import "./index.scss";

const CompanyRequired = () => {
  return (
    <div className="company-required">
      {DATA.map(({ title, content }) => (
        <div key={title} className="company-required-row">
          <PageTitle title={title} />
          <p>{content}</p>
        </div>
      ))}
    </div>
  );
};

export default CompanyRequired;
