import {PRICING_LIST} from "../../constants";

const getTotalAndPrice = (data, year = 1) => {
  const sum = (
    data.passengerVehiclesCount+
    data.straightTrucksAndTractorsCount+
    data.additionalVehiclesCount
  );
  const sumExemptions = (
    data.passengerVehiclesExemptionsCount+
    data.additionalVehiclesExemptionsCount+
    data.straightTrucksAndTractorsExemptionsCount
  );

  const total = sum-sumExemptions;
  const range = PRICING_LIST.find(({from, to}) => from <= total && total <= to);

  const res = {
    total,
    costForOneYear: range
      ? range.isLast
        ? null
        : range.price
      : null
  };

  return {
    ...res,
    totalCost: res.costForOneYear ? res.costForOneYear * year : res.costForOneYear
  };
};

export default getTotalAndPrice
