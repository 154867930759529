import React from "react";
import {Navigate, Route} from "react-router";
import {Routes} from "react-router-dom";

import APP_ROUTES from "../../routes";

import "./index.scss";

const Body = () => {
  return (
    <div className="body">
      <Routes>
        {APP_ROUTES.map(({path, component: Component}) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default Body;
