import cx from "classnames";
import PropTypes from "prop-types";

import "./index.scss";
import "./select.scss";

const Select = ({ classNames, action, required, label, disabled, children, error,  ...rest}) => {
  return (
    <div className="input-field select-field">
      {label && <label>{required && <span className="required-field">*</span>}{label}</label>}
      <select disabled={disabled} className={cx("select", `${classNames} ${action}`, {disabled, error})} {...rest} >
        {children}
      </select>
    </div>
  );
};

Select.propTypes = {
  action: PropTypes.oneOf([
    "flat",
    "outline"
  ]),
  required: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  action: "outline",
  required: false,
  disabled: false
};

export default Select;
