const MESSAGES = {
  CONTACT_INFO : {
    PHONE: "tel:8338338218",
    PHONE_TEXT: "833 833 8218",
    EMAIL: "info@ucr-us.com",
    EMAIL_TO: "mailto:info@ucr-us.com",
    WEB_PAGE: "https://dfactruckingauthority.com/",
    WEB_PAGE_TEXT: "dfactruckingauthority.com",
  },
  UNIFIED_CARRIER_REGISTRATION: "Unified Carrier Registration",
  ABOUT: "ABOUT",
  ABOUT_UCR_INFO: "ucr-us.com is run by a private third-party provider that charges fees for its services. It is a commercial advertisement and solicitation. We are not connected to the government authority (USDOT/FMCSA).",
  QUICK_LINKS: "QUICK_LINKS",
  QUICK_LINKS_CONTENT: [
    {
      LINK: "#",
      NAME: "FMCSA Safety and Fitness Electronic Records (SAFER) System"
    },
    {
      LINK: "#",
      NAME: "Contact UCR"
    },
    {
      LINK: "#",
      NAME: "Oregon Permits"
    },
    {
      LINK: "#",
      NAME: "Privacy Notice"
    },
  ],
  CONTACT_US: "CONTACT US",
  UCR: "UCR",
  REGISTRATION: "Registration",
  HOME: "Home",
  FAQ: "FAQ",
  REGISTRATION_HELP_TEXT: "At UCR Online, we've made managing your business a breeze. Our user-friendly platform ensures easy navigation, a streamlined dashboard, and hassle-free registration.",
  ENTER_USDOT_TO_BEGIN: "*Enter USDOT to begin",
  REGISTRATION_SECTION_MODAL_TEXT: "I acknowledge that I have read, understand, and agree to follow the UCR Online Privacy Notice and the UCR Website Terms of Use.",
  PRIVACY_POLICY: "PRIVACY POLICY",
  TERM_AND_CONDITION: "TERM & CONDITIONS",
  SUBMIT: "SUBMIT",
  MAP_SECTION_TITLE: "Please note: All interstate businesses that are located in non-participating states are still subject to UCR.",
  PARTICIPATING_STATES: "Participating States",
  NON_PARTICIPATING_STATES: "Non-Participating States",
  MAP_SECTION_TEXTS: [
    {
      TITLE: "Welcome to our customer support section!",
      TEXT: "We're here to help you with any questions or issues you may have."
    },
    {
      TITLE: "Contacting Us:",
      TEXT: "If you need to reach out to us, you can contact our support team through email, phone or by filling out our customer support form."
    },
    {
      TITLE: "Frequently Asked Questions:",
      TEXT: "Before you contact us, you may find the answer to your question in our FAQ section. We've compiled a list of the most commonly asked questions and their answers for"
    },
  ],
  NAME: "Your Name",
  EMAIL: "Your Email",
  SUBJECT: "Your Subject",
  MESSAGE: "Your Message",
  SEND: "Send",
  ABOUT_UCR_TEXTS: {
    FIRST_BLOCK_TEXTS: [
      "Welcome to DFAC - Your Reliable Partner for DOT Filing & Compliance Solutions.",
      "Our website is designed to streamline the process of obtaining all necessary paperwork and tax preparations for your trucking business. As a third-party provider, we specialize in facilitating these tasks, ensuring that you have everything you need to operate smoothly and efficiently.",
      "DFAC has helped numerous trucking businesses streamline their paperwork processes. If you're looking for assistance with your paperwork or want to learn more about our services, don't hesitate to Contact Us. We're here to help you achieve greater efficiency and success in your operations.",
      "At DFAC, we’re your trusted third-party resource for all your DOT filing and compliance needs. It’s important to note that while we’re not affiliated with the Federal Motor Carrier Safety Administration (FMCSA), we’re committed to providing exceptional services tailored specifically to your trucking business.",
    ],
    SECOND_BLOCK_TEXTS: [
      "Registration for the Unified Carrier Registration Plan depends on the number of trucks in your fleet. These fees help fund highway safety programs and initiatives. Any money made from these funds is directly distributed to the participating states. This is to help enforce laws, maintain roads, and generally make driving in these states as safe as possible.",
      "The fees for the UCR are due every year. These fees are subject to change at any time, but luckily, if you pay and the price goes up the next month, you are not responsible for paying the difference. Drivers may not even notice the rate change until the following year when they pay their next annual UCR payment. Registration begins in the fall, but payment is not due until December 31st, the end of the year.",
      "New drivers or new companies that open for business need to pay the UCR before their first interstate trip. It is important to never get caught crossing state or federal borderlines without a UCR certificate. Otherwise, drivers may be subject to fines, fees, and could have their vehicle impounded. Get your UCR today, and you'll be able to travel freely across the United States and beyond!",
    ]
  },
  PRICING: "PRICING",
  CUSTOMER_PRICE_PLAN: "CUSTOMER PRICE PLAN",
  REGISTER_NOW: "REGISTER NOW",
  UCR_UNIFIED_CARRIER_REGISTRATION_FORM: "UCR - Unified Carrier Registration Form",
  CARRIER_INFO: "Carrier info",
  CONFIRM_YOUR_OFFICIAL_REPRESENTATIVE: "Confirm Your Official Representative",
  ADDRESS_DETAILS: "Address details",
  REGISTRATION_DETAILS: "Registration Details",
  NUMBER_OF_REGISTERED_VEHICLES: "Number of registered vehicles",
  PAYMENT_INFO: "Payment Info",
  PAY_TO_COMPLETE_REGISTRATION: "Pay to Complete Registration",
  USDOT: "USDOT",
  USDOT_NUMBER: "USDOT Number",
  SELECT_REGISTRATION_YEAR: "Select registration year",
  EMAIL_ADDRESS: "Email Address",
  FULL_NAME: "Full Name",
  FIRST_AND_LAST: "First and Last",
  BUSINESS_PHONE: "Business Phone",
  MOBILE: "Mobile",
  ENTER_YOUR_CONTACT_MOBILE_NUMBER: "Enter your Contact Mobile number",
  ENTER_YOUR_CONTACT_BUSINESS_PHONE: "Enter your Contact Business phone",
  CHECK_IF_MOBILE_NUMBER_IS_THE_SAME_AS_BUSINESS_PHONE: "(check if mobile number is the same as business phone.)",
  CHECK_IF_MAILING_ADDRESS_IS_THE_SAME_AS_PHSICAL_ADDRESS: "(check if the mailing address is the same as physical address.)",
  CARRIER_TERMS: (id) => `I certify that i have been given proper authorization to perform or modify UCR registration information for USDOT ${id}`,
  PREVIOUS: "Previous",
  NEXT: "Next",
  INTRASTATE_NOTICE: "Intrastate Notice",
  WHO_IS_CREATING_THIS_REGISTRATION: "Who is creating this registration?",
  I_AGREE_THAT_MY_BUSINESS_IS_SUBJECT_TO_UCR: "I agree that my business is subject to UCR",
  READ_MORE: "read more...",
  LEGAL_NAME: "Legal Name",
  DBA: "DBA",
  PHYSICAL_ADDRESS: "Physical Address",
  MAILING_ADDRESS: "Mailing Address",
  ADDRESS: "Address",
  CITY: "City",
  STATE: "State",
  ZIP_CODE: "Zip Code",
  ARE_YOU_UPDATING_ANY_OF_THE_COMPANY_OPERATIONS_CLASSIFICATION: "Are you updating any of the company's Operations Classification?",
  YES: "Yes",
  NO: "No",
  CARRIER_CLASSIFICATION: "Carrier Classification",
  OPERATIONS_CLASSIFICATION: "Please select the list of updated Operations Classifications below",
  VEHICLES: "Vehicles",
  OPTION_A: "Option A - Autofilled from section 26 of your last reported MCS-150/MCSA-1 form.",
  OPTION_B: "Option B - Manually enter total vehicles operated for 12 month period ending June 30, 2021",
  EXEMPTIONS: "Exemptions",
  STRAIGHT_TRUCKS_AND_TRACTORS: "STRAIGHT TRUCKS & TRACTORS",
  PASSENGER_VEHICLES: "PASSENGER VEHICLES",
  ADDITIONAL_VEHICLES: "ADDITIONAL VEHICLES",
  VEHICLE_TOTAL: "VEHICLE TOTAL",
  TOTAL_COST_FOR_YEAR: "COST FOR YEAR",
  TOTAL_COST: "TOTAL COST",
  PAY_WITH_CREDIT_CARD: "Pay with credit card:",
  BILLING_ADDRESS: "Billing Address",
  NEW_ADDRESS: "New Address",
  SAME_AS_PHYSICAL_ADDRESS: "Same as Physical Address",
  SAME_AS_MAILING_ADDRESS: "Same as Mailing Address ",
  CARD_NUMBER: "Card Number",
  MM_YY: "MM/YY",
  CVC: "CVC",
  COMPANY_REQUIRED_TITLE: "Determine whether you are required to register with the UCR",
  CONTACT_US_DIRECTLY: "Contact us directly.",
  OPTION_B_WARNINGS: "Option B Warnings",
  THANK_YOU: "Thank You",
  THANK_YOU_DESCRIPTION: "Thank you for completing your registration with UCR.online. Our tax experts have received your details and are actively working alongside authorities to finalize your registration. You can expect to receive an email within 15 minutes containing your registration approval and certificate. Once you have obtained this certificate, your vehicle(s) will be officially registered, allowing you to continue your business operations.",
  RETURN_TO_HOME_PAGE: "RETURN TO HOME PAGE",
  SIGNATURE: "Signature",
  APPLY_AND_PAY: "Apply and Pay",
  CLEAR: "Clear",
  MSC_150: {
    LINK: "https://mcs150-form.online/",
    LINK_TEXT: "Update/Create your MCS-150 Form Now: Stay Compliant and Up-to-Date",
    OUTDATED: "Update MCS 150",
  },
  CLICK_HERE: "Click Here",
  HERE: "here",
  ADD_YEAR: "Add Year",
  DELETE_YEAR: "Delete Year",
  YEARS: "YEARS",
  OTHER: "Other",
  REFUND: "Refund",
  QUICK_QUESTION: "Quick Question",
  NEED_HELP: "Need Help",
};

export default MESSAGES;
