import API from "./API";

const API_END_POINT = "https://oregontruckback.azurewebsites.net/api/UCR/";
const API_END_POINT_US_DOT = `${API_END_POINT}dotInfo/`;

const API_END_POINT_CARRIER_INFO = `${API_END_POINT}carrierInfo/`;
const API_END_POINT_ADDRESS_INFO = `${API_END_POINT}addressInfo/`;
const API_END_POINT_OPERATIONS = `${API_END_POINT}operationsClassifications/`;
const API_END_POINT_CARRIER_CLASSIFICATIONS = `${API_END_POINT}carrierClassifications/`;
const API_END_POINT_REGISTRATION_DETAILS = `${API_END_POINT}registrationDetails/`;
const API_END_POINT_PAYMENT = `${API_END_POINT}payment/`;
const API_END_POINT_CONTACT = `${API_END_POINT}contact/`;

export const getOperationsClassifications = () => API.GET(API_END_POINT_OPERATIONS);

export const getCarrierClassifications = () => API.GET(API_END_POINT_CARRIER_CLASSIFICATIONS);

export const getUsDotById = id => API.GET(`${API_END_POINT_US_DOT}${id}`);

export const editCarrierInfoById = (id, data) => API.PUT(`${API_END_POINT_CARRIER_INFO}${id}`, data);

export const editAddressInfoById = (id, data) => id
  ? API.PUT(`${API_END_POINT_ADDRESS_INFO}${id}`, data)
  : API.POST(`${API_END_POINT_ADDRESS_INFO}`, data);

export const editRegistrationDetails = (id, data) => API.PUT(`${API_END_POINT_REGISTRATION_DETAILS}${id}`, data);

export const makePayment = (id, data) => API.POST(`${API_END_POINT_PAYMENT}${id}`, data);

export const sendContact = (data) => API.POST(`${API_END_POINT_CONTACT}`, data);
