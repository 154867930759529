const ITEMS = [
  {
    title: "Is My Business Required to Register?",
    content: "Yes, if part of your business requires interstate travel, then you must submit your Unified Carrier Registration. In 2005, the federal government passed a law that all individuals or companies that operate commercial motor vehicles across state or international lines must pay an annual fee based on the size of their fleet. This fee helps maintain public roads and invests in driver safety programs. It is mandatory to register every year you are in business. Don’t waste money on unnecessary fines or worse. Let us help you register your vehicle or whole fleet today!\n"
  },
  {
    title: "What if My State is not Part of the UCR Agreement?",
    content: "You still must submit your Unified Carrier Registration if your business crosses state or federal lines. Canadian-based businesses and drivers who reside in states that don’t participate in the UCR Agreement still need to follow the Unified Carrier Registration Act of 2005. Don’t get caught at the border without properly registering first. Drivers caught on the road without proper registration are subject to fines and can even have their vehicle impounded. We can help you find a state to register with and get yourself safely back on the road."
  },
  {
    title: "What is the purpose of UCR registration?",
    content: "The purpose of UCR registration is to ensure that motor carriers, brokers, and freight forwarders comply with federal regulations and help fund state enforcement efforts, motor carrier safety programs, and other related activities."
  },
  {
    title: "How often do I need to renew my UCR registration?",
    content: "UCR registration is an annual requirement, and you must renew it every year. The registration period usually opens in October for the following year."
  },
  {
    title: "What documents and information are required for UCR registration?",
    content: "During the UCR registration process, you will typically need to provide information such as your company name, address, contact details, USDOT number (if applicable), fleet size, and other relevant information. Each state may have specific requirements, so it's advisable to check their website for detailed instructions."
  },
  {
    title: "Where can I find more information about UCR registration?",
    content: <span>For more detailed information about UCR registration, fee calculation, participating states, and other related topics, you can <a href='/about-ucr-registration'>Click Here</a></span>
  }
];

export default ITEMS;
