import ReCAPTCHA from "react-google-recaptcha";

import "./index.scss";

const Captcha = ({ onChange }) => {
  return (
    <div className="ucr-captcha">
      <ReCAPTCHA
        size="normal"
        onChange={onChange}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
    </div>
  );
};

export default Captcha;
