import cx from "classnames";
import {useState} from "react";
import validate from "./validate";
import {useAlert} from "react-alert";
import Input from "../../../ui/Input";
import Button from "../../../ui/Button";
import Loading from "../../../ui/Loading";
import Captcha from "../../../ui/Captcha";
import Select from "../../../ui/Input/Select";
import {sendContact} from "../../../Services";
import Textarea from "../../../ui/Input/Textarea";
import MESSAGES from "../../../constants/messages";

import "./index.scss";

const defaultState = {
  name: "",
  email: "",
  message: "",
  subject: MESSAGES.OTHER,
};
const ContactUsForm = () => {
  const alert = useAlert();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(defaultState);
  const [err, setErr] = useState({err:{}, isValid: true});

  const handleChange = e => {
    const {value, name} = e.target;
    setData(prev => {
      const data = {...prev, [name]: value};
      if(!err.isValid) {
        const res = validate(data);
        setErr(res);
      }
      return data;
    })
  };

  const onClick = () => {
    const res = validate(data);
    if (!res.isValid) {
      setErr(res.err);
      return;
    }
    setLoading(true);
    sendContact(data)
      .then(response => {
        if (response.hasError) {
          response.errors.map(({ errorMessage }) => alert.show(errorMessage, {type: "error"}));
        } else {
          alert.show("Success!", {type: "success"});
          setData(defaultState);
        }
      })
      .catch(err => {
        alert.show(err.message || err, {type: "error"});
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="contact-us-form loading-container">
      <Loading isLoading={loading} />
      <Select
        required
        name="subject"
        error={err.subject}
        onChange={handleChange}
        label={MESSAGES.SUBJECT}
      >
        <option value={MESSAGES.OTHER}>{MESSAGES.OTHER}</option>
        <option value={MESSAGES.REFUND}>{MESSAGES.REFUND}</option>
        <option value={MESSAGES.QUICK_QUESTION}>{MESSAGES.QUICK_QUESTION}</option>
        <option value={MESSAGES.NEED_HELP}>{MESSAGES.NEED_HELP}</option>
      </Select>
      <Input
        required
        name="name"
        value={data.name}
        label={MESSAGES.NAME}
        onChange={handleChange}
        classNames={cx("full-width", {error: err.name})}
      />
      <Input
        required
        name="email"
        value={data.email}
        label={MESSAGES.EMAIL}
        onChange={handleChange}
        classNames={cx("full-width", {error: err.email})}
      />
      <Textarea
        required
        name="message"
        value={data.message}
        onChange={handleChange}
        label={MESSAGES.MESSAGE}
        classNames={cx("full-width", {error: err.message})}
      />
      <Captcha onChange={setToken} />
      <Button type="solid" classNames="full-width" disabled={!err.isValid || !token} onClick={onClick}>{MESSAGES.SEND}</Button>
    </div>
  );
};

export default ContactUsForm;
