import {useState} from "react";
import Button from "../../ui/Button";
import {useParams} from "react-router";
import Loading from "../../ui/Loading";
import Stepper from "../shared/Stepper";
import useOnce from "../../hooks/useOnce";
import PageTitle from "../../ui/PageTitle";
import {getUsDotById} from "../../Services";
import {useNavigate} from "react-router-dom";
import MESSAGES from "../../constants/messages";
import {defaultStates, STEPS} from "../shared/Stepper/steps";

import "./index.scss";

const Registration = () => {
  const { usDot } = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({Msc150: false});
  const[states, setStates] = useState(defaultStates);
  const {Component, title, key} = STEPS[activeStep];

  useOnce(() => {
    setLoading(true);
    getUsDotById(usDot)
    .then(response => {
      if (response.HasError !== false) {
        navigate(`/`);
      } else {
        setData(response.Data);
      }
    })
    .catch(() => {
      navigate(`/`);
    }).finally(() => setLoading(false));
  });

  const onNext = () => setActiveStep(activeStep+1);

  const onPrev = () => activeStep > 0 && setActiveStep(activeStep-1);

  const onSetStates = (data, key) => setStates(prev => ({
    ...prev,
    [key]: data
  }));

  return loading ? <Loading isLoading={loading} /> : (
    <div className="container-fluid registration-page loading-container">
      <div className="container">
        <PageTitle title={MESSAGES.UCR_UNIFIED_CARRIER_REGISTRATION_FORM} />
        <div className="content-with-stepper">
          <div className="stepper-side">
            <div className="us-dot">{MESSAGES.USDOT} - {usDot}</div>
            <Stepper
              activeStep={activeStep}
            />
          </div>
          <div className="stepper-content loading-container">
            <PageTitle
              title={MESSAGES.MSC_150.OUTDATED}
              classNames={data.Msc150 ? "danger msc-info" : "msc-info"}
            >
              <a href={MESSAGES.MSC_150.LINK}>
                <Button classNames={data.Msc150 ? "danger" : ""} type="solid">{MESSAGES.HERE}</Button>
              </a>
            </PageTitle>
            <PageTitle title={title} />
            <div className="stepper-content-wrapper">
              <Component
                id={id}
                data={data}
                setId={setId}
                onNext={onNext}
                onPrev={onPrev}
                state={states[key]}
                carrierInfo={states.carrierInfo}
                addresses={states.addressDetails}
                saveState={data => onSetStates(data, key)}
                registrationDetails={states.registrationDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
