import React from "react";
import Body from "./Main/Body";
import Header from "./Main/Header";
import Footer from "./Main/Footer";

import "./scss/index.scss";

const App = () => {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
}

export default App;
