import cx from "classnames";
import PropTypes from "prop-types";

import "./index.scss";

const Textarea = ({ classNames, action, label, required, ...rest}) => {
  return (
    <div className="input-field">
      {label && <label>{required && <span className="required-field">*</span>}{label}</label>}
      <textarea className={cx("textarea", `${classNames} ${action}`)} {...rest} />
    </div>
  );
};

Textarea.propTypes = {
  action: PropTypes.oneOf([
    "flat",
    "outline"
  ]),
  required: PropTypes.bool,
};

Textarea.defaultProps = {
  action: "outline",
  required: false
};

export default Textarea;
