import "./index.scss";

const InfoPages = ({ children }) => {
  return (
    <div className="container-fluid info-pages">
      <div className="container">{children}</div>
    </div>
  );
};

export default InfoPages;
