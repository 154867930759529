import cx from "classnames";
import PropTypes from "prop-types";

import "./radio.scss";

const Radio = ({ classNames, checked, name, action, required, label, disabled, onChange, ...rest}) => {
  return (
    <div className="radio-field">
      <input checked={checked} type="radio" name={name} disabled={disabled} className={cx("radio", `${classNames} ${action}`, {disabled})} onChange={onChange} {...rest} />
      {label && <label>{required && <span className="required-field">*</span>}{label}</label>}
    </div>
  );
};

Radio.propTypes = {
  action: PropTypes.oneOf([
    "flat",
    "outline"
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  name: PropTypes.string.isRequired,
};

Radio.defaultProps = {
  action: "outline",
  required: false,
  disabled: false
};

export default Radio;
