import USAMap from "react-usa-map";
import PropTypes from "prop-types";
import statesJson from "./states.json";
import useOnce from "../../hooks/useOnce";
import { useEffect, useState } from "react";

import "./index.scss";

const Map = ({ defaultColor, specificStateColors }) => {
  const states = statesJson.data;
  const colors = Object.values(states).reduce((acc, curr) => {
    const {abbreviation} = curr.attributes
    return {
      ...acc,
      [abbreviation]: {fill: specificStateColors[abbreviation] || defaultColor}
    }
  }, {});

  const [condition, setCondition] = useState({
    ongoing: false,
    end: false,
    progNum: 0,
    correctCnt: 0,
    falseCnt: 0,
    failed: false,
    quizMode: null
  });
  const [colorMap, setColorMap] = useState(colors);
  const [quizArray, setQuizArray] = useState(states);
  const [qAbbreviation, setqAbbreviation] = useState(null);

  const shuffle = ([...array]) => {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    setQuizArray((quizArray) => shuffle(quizArray));
    console.log("shuffled");
  }, [condition.end]);

  useEffect(() => {
    setqAbbreviation(quizArray[condition.progNum].attributes.abbreviation);
  }, [quizArray, condition.progNum]);

  const mapHandler = (e) => {
    const { ongoing, end, progNum, correctCnt, falseCnt, failed } = condition;
    if (end || progNum >= 50) return;
    if (ongoing) {
      const guessAbbreviation = e.target.dataset.name;
      if (guessAbbreviation === qAbbreviation) {
        const nextProgNum = progNum + 1;
        if (failed)
          setCondition({ ...condition, failed: false, progNum: nextProgNum });
        else {
          setCondition({
            ...condition,
            correctCnt: correctCnt + 1,
            progNum: nextProgNum
          });
        }
      } else {
        if (!failed) {
          setCondition({ ...condition, falseCnt: falseCnt + 1, failed: true });
        }
        const newColorMap = colorMap;
        newColorMap[guessAbbreviation] = { fill: "#04365B" };
        setColorMap(newColorMap);
      }
    }
  };

  const startSession = () => {
    setCondition({ ...condition, ongoing: true, progNum: 0, end: false });
  };

  useOnce(() => {
    startSession();
  });

  return (
    <div className="states-map">
      <USAMap customize={colorMap} onClick={mapHandler} />
    </div>
  );
};

Map.propTypes = {
  defaultColor: PropTypes.string,
  selectedColor: PropTypes.string,
  specificStateColors: PropTypes.object,
};

Map.defaultProps = {
  defaultColor: "#04365B",
  selectedColor: "#B70C18",
  specificStateColors:  {
    OR: "#A4A8B3",
    NV: "#A4A8B3",
    AZ: "#A4A8B3",
    WY: "#A4A8B3",
    FL: "#A4A8B3",
    VT: "#A4A8B3",
    NJ: "#A4A8B3",
    MD: "#A4A8B3",
    HI: "#A4A8B3",
  }
};

export default Map
