import PageTitle from "../../ui/PageTitle";
import Accordion from "../../ui/Accordion";
import MESSAGES from "../../constants/messages";
import ITEMS from "./constants";

import "./index.scss";

const FAQ = () => {
  return (
    <div className="container-fluid faq-section">
      <div className="container">
        <PageTitle title={MESSAGES.FAQ} />
        <div className="faq-block">
          <Accordion items={ITEMS} />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
