export const focusErrorInput = () => {
  document.addEventListener("click", (e) => {
    if (e.target?.nodeName === "BUTTON") {
      setTimeout(() =>{
        const elems = document.getElementsByClassName("error");
        if (elems.length > 0) {
          elems[0]?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        }
      });
    }
  })
};


export const formatNumberToShow = (number = "") => {
  if (typeof number === "string") {
    number = parseInt(number.replace(",", ""));
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
