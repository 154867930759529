const validate = data => {
  const err = {};
  let isValid = true;
  Object.keys(data).map(item => {
    if (data[item] === "") {
      err[item] = true;
      isValid = false;
    } else {
      err[item] = false;
    }
    return true
  });

  return {
    err,
    isValid
  };
};

export default validate;
