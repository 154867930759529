import Pricing from "./Pricing";
import AboutUcrRegistration from "./AboutUcrRegistration";

import "./index.scss";

const AboutRegistration = () => {
  return (
    <div className="about-registration">
      <AboutUcrRegistration />
      <Pricing />
    </div>
  );
};

export default AboutRegistration
