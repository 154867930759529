import FAQ from "./components/FAQ";
import Home from "./components/Home";
import MESSAGES from "./constants/messages";
import ContactUs from "./components/ContactUs";
import SuccessPage from "./components/SuccessPage";
import Registration from "./components/Registration";
import AboutRegistration from "./components/AboutRegistration";
import PrivacyAndPolicy from "./components/InfoPages/PrivacyAndPolicy";
import TermsAndCondition from "./components/InfoPages/TermsAndCondition";

import logo from "./scss/img/ucr-logo.png";

const APP_ROUTES = [
  {
    path: "/",
    component: Home,
    title: MESSAGES.HOME,
    logoSrc: logo
  },
  {
    hideMenu: true,
    path: "/ucr-registration/:usDot",
    component: Registration,
    title: MESSAGES.UCR
  },
  {
    path: "/about-ucr-registration",
    component: AboutRegistration,
    title: MESSAGES.ABOUT
  },
  {
    path: "/faq",
    component: FAQ,
    title: MESSAGES.FAQ
  },
  {
    hideMenu: true,
    path: "/contact-us",
    component: ContactUs,
    title: MESSAGES.CONTACT_US
  },
  {
    hideMenu: true,
    path: "/success-page",
    component: SuccessPage,
    title: MESSAGES.THANK_YOU
  },
  {
    hideMenu: true,
    path: "/privacy-and-policy",
    component: PrivacyAndPolicy,
    title: MESSAGES.PRIVACY_POLICY
  },
  {
    hideMenu: true,
    path: "/terms-and-condition",
    component: TermsAndCondition,
    title: MESSAGES.TERM_AND_CONDITION
  }
];

export default APP_ROUTES;
