import {useCallback} from "react";
import PropTypes from "prop-types";
import Button from "../../ui/Button";
import Select from "../../ui/Input/Select";
import MESSAGES from "../../constants/messages";

import "./SelectYear.scss";

const SelectYear = ({ years, selected, onChange, addNewYearRow, deleteYear }) => {

  const getOptions = useCallback((i) => {
    const options = years.filter(year => year === selected[i] || selected.indexOf(year) === -1);

    return options.map((y) => (
      <option value={y} key={y}>{y}</option>
    ));
  }, [selected, years]);

  return (
    <div>
      {selected.map((v, i) => (
        <div key={v} className="select-year-row">
          <Select
            required
            action="outline"
            value={selected[i]}
            classNames="full-width"
            label={MESSAGES.SELECT_REGISTRATION_YEAR}
            onChange={e => onChange(e, i)}
          >
            {getOptions(i)}
          </Select>
          <Button
            classNames="danger"
            onClick={() => deleteYear(i)}
            disabled={selected.length < 2 }
          >
            {MESSAGES.DELETE_YEAR}
          </Button>
          {selected.length-1 === i && (
            <Button
              onClick={addNewYearRow}
              disabled={years.length === selected.length}
            >
              {MESSAGES.ADD_YEAR}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

SelectYear.propTypes = {
  years: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  deleteYear: PropTypes.func.isRequired,
  addNewYearRow: PropTypes.func.isRequired,
};

export default SelectYear;
