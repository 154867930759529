import "./index.scss";

const DropDown = () => {
  const onClick = e => {
    e.preventDefault();
  };

  return (
    <div className="drop-down" id="drop-down">
      <a href="#/other" onClick={onClick}>OTHER SERVICES</a>
      <div className="drop-down-list">
        <div className="drop-down-list-item">
          <a target="_blank" rel="noreferrer" href="https://oregontruckspermit.com/">Oregon Trucks Permit</a>
        </div>
        <div className="drop-down-list-item">
          <a target="_blank" rel="noreferrer" href="https://dfactruckingauthority.com/">Trucking Authority</a>
        </div>
        <div className="drop-down-list-item">
          <a target="_blank" rel="noreferrer" href="https://mcs150-form.online/">MCS-150 Form Online</a>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
