import MapSection from "./MapSection";
import RegistrationSection from "./RegistrationSection";

const Home = () => (
  <>
    <RegistrationSection />
    <MapSection />
  </>
);

export default Home
