import PropTypes from "prop-types";
import Step from "../../../ui/Step";
import {STEPS} from "./steps";

import "./index.scss";

const Stepper = ({activeStep}) => {
  return (
    <div className="ucr-stepper">
      {STEPS.map((st, i) => (
        <Step
          key={st.title}
          title={st.title}
          active={i <= activeStep}
          description={st.description}
        />
      ))}
    </div>
  );
};

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
}

export default Stepper;
