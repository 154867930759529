import Icon from "../../ui/Icon";
import Button from "../../ui/Button";
import PageTitle from "../../ui/PageTitle";
import {useNavigate} from "react-router-dom";
import MESSAGES from "../../constants/messages";

import "./index.scss";

const SuccessPage = () => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid success-page">
      <div className="container">
        <PageTitle title={MESSAGES.THANK_YOU} />
        <div className="content-block">
          <div>
            <Icon type="BsFillCheckCircleFill" />
          </div>
          <div>
            {MESSAGES.THANK_YOU_DESCRIPTION}
          </div>
        </div>
        <div className="footer-block">
          <Button onClick={() => navigate("/")}>{MESSAGES.RETURN_TO_HOME_PAGE}</Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
