import PriceList from "./PriceList";
import PageTitle from "../../../ui/PageTitle";
import MESSAGES from "../../../constants/messages";
import PricingDescription from "./PricingDescription";

import "./index.scss";

const Pricing = () => {
  return (
    <div className="container-fluid ucr-pricing">
      <div className="container">
        <PageTitle title={MESSAGES.PRICING} />
        <div className="flex">
          <PricingDescription />
          <PriceList />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
