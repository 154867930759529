import PageTitle from "../../ui/PageTitle";
import ContactUsForm from "./ContactUsForm";
import MESSAGES from "../../constants/messages";

import "./index.scss";

const ContactUs = () => {
  return (
    <div className="container-fluid contact-us-section">
      <div className="container">
        <PageTitle title={MESSAGES.CONTACT_US} />
        <div className="working-hours">
          <p>MON-FRI 09:00AM - 07:00PM, SAT-SUN 10:00AM - 02:00PM</p>
          <p>Email: <a href="mailto:info@ucr-us.com">info@ucr-us.com</a></p>
        </div>
        <ContactUsForm />
      </div>
    </div>
  );
};

export default ContactUs;
