import validate from "./validate";
import PropTypes from "prop-types";
import Input from "../../ui/Input";
import {useAlert} from "react-alert";
import {useParams} from "react-router";
import Loading from "../../ui/Loading";
import {useEffect, useState} from "react";
import Select from "../../ui/Input/Select";
import STATES from "../../constants/states";
import MESSAGES from "../../constants/messages";
import {editAddressInfoById} from "../../Services";
import StepperFooter from "../shared/Stepper/components/StepperFooter";

import "./index.scss";

const AddressDetails = ({onNext, data, id, setId, state:propsState, saveState:propsSaveState}) => {
  const alert = useAlert();
  const {usDot} = useParams();
  const [loading, setLoading] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [errors, setErrors] = useState({hasError: false, errors: {}});
  const [state, setState] = useState({
    ...data,
    mailingCity: "",
    mailingStreet: "",
    mailingZipCode: "",
    mailingState: STATES[0].State,
    ...propsState,
    phCity: data.City,
    phState: data.State,
    dbaName: data.DBAName,
    phStreet: data.Street,
    phZipCode: data.ZipCode,
    legalName: data.LegalName,
  });

  const selectSameAddress = (e) => {
    const {checked} = e.currentTarget;
    setIsSameAddress(checked);
    checked && setState(prev => ({
      ...prev,
      mailingCity: data.City,
      mailingState: data.State,
      mailingStreet: data.Street,
      mailingZipCode: data.ZipCode,
    }));
  };

  useEffect(() => {
    if(errors.hasError) {
      setErrors(validate(state));
    }
  }, [errors.hasError, state]);

  const onChange = (e, key) => {
    setState(prev => ({
      ...prev,
      [key]: e.target.value
    }));
  };

  const onSave = () => {
    const d = {
      usdot: usDot,
      phCity: state.phCity,
      phState: state.phState,
      dbaName: state.dbaName,
      phStreet: state.phStreet,
      legalName: state.legalName,
      phZipCode: state.phZipCode,
      mailingCity: state.mailingCity,
      mailingState: state.mailingState,
      mailingStreet: state.mailingStreet,
      mailingZipCode: state.mailingZipCode
    };
    const res = validate(d);
    if (res.hasError) {
      setErrors(res);
      return;
    }

    setLoading(true);
    editAddressInfoById(id, d)
      .then(response => {
        if (response.HasError) {
          response.errors.map(({ errorMessage }) => alert.show(errorMessage, {type: "error"}));
        } else {
          response.Data && setId(response.Data);
          propsSaveState(d);
          onNext();
        }
      })
      .catch(err=> {
        alert.show(err.message || err, {type: "error"});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Input
        disabled
        value={state.legalName}
        label="Legal Name"
        wrapperClass="full"
      />
      <Input
        disabled
        value={state.dbaName}
        label="DBA"
        wrapperClass="full"
      />
      <p className="group-input-label">{MESSAGES.PHYSICAL_ADDRESS}</p>
      <div className="address-row">
        <Input
          disabled
          value={state.phStreet}
        />
        <Input
          disabled
          value={state.phCity}
        />
        <Select
          disabled
          value={state.phState}
        >
          {STATES.map(m => (
            <option value={m.state} key={m.name}>{m.state} {m.name}</option>
          ))}
        </Select>
        <Input
          disabled
          value={state.phZipCode}
        />
      </div>
      <p className="group-input-label">
        <span className="required">*</span>
        {MESSAGES.MAILING_ADDRESS}
        <span className="label-input">
          <input type="checkbox" onChange={selectSameAddress} checked={isSameAddress}/>
          {MESSAGES.CHECK_IF_MAILING_ADDRESS_IS_THE_SAME_AS_PHSICAL_ADDRESS}
        </span>
      </p>
      <div className="address-row">
        <Input
          required
          disabled={isSameAddress}
          value={state.mailingStreet}
          placeholder={MESSAGES.ADDRESS}
          error={errors.errors.mailingStreet}
          onChange={e => onChange(e, "mailingStreet")}
        />
        <Input
          disabled={isSameAddress}
          value={state.mailingCity}
          placeholder={MESSAGES.CITY}
          error={errors.errors.mailingCity}
          onChange={e => onChange(e, "mailingCity")}
        />
        <Select
          disabled={isSameAddress}
          value={state.mailingState}
          onChange={e => onChange(e, "mailingState")}
        >
          {STATES.map(m => (
            <option value={m.state} key={m.name}>{m.state} {m.name}</option>
          ))}
        </Select>
        <Input
          disabled={isSameAddress}
          value={state.mailingZipCode}
          placeholder={MESSAGES.ZIP_CODE}
          error={errors.errors.mailingZipCode}
          onChange={e => onChange(e, "mailingZipCode")}
        />
      </div>
      <StepperFooter
        onClickNext={onSave}
        disabledNext={errors.hasError}
      />
      <Loading isLoading={loading} />
    </div>
  );
};

AddressDetails.propTypes = {
  setId: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  saveState: PropTypes.func.isRequired,
  data: PropTypes.shape({
    City: PropTypes.string.isRequired,
    State: PropTypes.string.isRequired,
    Street: PropTypes.string.isRequired,
    ZipCode: PropTypes.string.isRequired,
    LegalName: PropTypes.string.isRequired,
  }).isRequired
};

export default AddressDetails;
