import Icon from "../Icon";
import Button from "../Button";
import PropTypes from "prop-types";

const ModalTitle = ({ title, onClose }) => {
  return (
    <div className="modal-title">
      <div className="title">{title}</div>
      {onClose && (
        <div className="close">
          <Button type="solid" onClick={onClose}>
            <Icon type="BsX" />
          </Button>
        </div>
      )}
    </div>
  );
};

ModalTitle.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default ModalTitle;
