import Button from "../../../../ui/Button";
import PageTitle from "../../../../ui/PageTitle";

import "./index.scss";

const IntrastateNotice = ({readMore}) => {
  return (
    <div className="intrastate-notice">
      <p>All businesses that plan to engage in interstate commerce and are defined by the categories listed below must submit a UCR. Please take a moment to ensure that your business falls into one of the following categories:</p>
      <ul>
        <li>Motor Carriers</li>
        <li>For-hire passenger motor carriers</li>
        <li>Freight Forwarders</li>
        <li>Brokers</li>
        <li>Leasing Companies</li>
      </ul>
      <p>Please note that motor carriers, both private and for-hire, that transport property are subject to UR registration even if they are exempt for purposes of federal regulation.</p>
      <PageTitle title="Interstate Commerce" />
      <p>Interstate commerce refers to any movement of goods or passengers across state lines or across international borders in the United States during any stage of your journey. Registration is also required if movement of a shipment crosses state or international borders through multiple modes of transportation even if the trucking portion of thattransportation remains entirely within state.</p>
      <p>This notice states that you are confirming that your business meets the criteria to be subject to UCR and registration fees. Please <Button type="link" onClick={readMore}>CLICK HERE</Button> to confirm that your company is required</p>
    </div>
  );
};

export default IntrastateNotice;
