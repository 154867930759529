import React from "react";
import ReactDOM from "react-dom/client";
// import TagManager from "react-gtm-module";
import {BrowserRouter} from "react-router-dom";
import AlertTemplate from "react-alert-template-basic";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

import App from "./App";
import {focusErrorInput} from "./utils";

// optional configuration
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "10px",
  transition: transitions.SCALE
};

// TagManager.initialize({
//   gtmId: process.env.REACT_APP_TAG_MANAGER_ID
// });

focusErrorInput();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename="/">
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </BrowserRouter>
);


