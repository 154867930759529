import PropTypes from "prop-types";
import PageTitle from "../PageTitle";

import "./index.scss";

const Index = ({ title, text }) => (
  <div className="text-with-title">
    <PageTitle title={title} />
    <p>{text}</p>
  </div>
);

Index.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Index;
