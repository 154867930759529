import cx from "classnames";
import PropTypes from "prop-types";

import "./index.scss";

const Input = ({ wrapperClass, classNames, action, error, required, label, disabled, innerRef, ...rest}) => {
  return (
    <div className={cx("input-field", wrapperClass)}>
      {label && <label>{required && <span className="required-field">*</span>}{label}</label>}
      <input ref={innerRef} disabled={disabled} className={cx("input", `${classNames} ${action}`, {disabled, error})} type="text" {...rest} />
    </div>
  );
};

Input.propTypes = {
  error: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  wrapperClass: PropTypes.string,
  action: PropTypes.oneOf([
    "flat",
    "outline"
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
};

Input.defaultProps = {
  error: false,
  required: false,
  disabled: false,
  action: "outline",
  wrapperClass: "",
};

export default Input;
