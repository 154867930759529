const validate = (data) => {
  const errors = {
    carrierClassificationIds: data.carrierClassificationIds.length === 0,
    operationClassificationIds: data.isUpdatingOperationsClassification && data.operationClassificationIds.length === 0,
  };

  return {
    errors,
    hasError: Object.values(errors).some(r => r)
  };

};

export default validate
