import Modal from "../Modal";
import Button from "../Button";
import PropTypes from "prop-types";
import MESSAGES from "../../constants/messages";
import {useEffect, useRef, useState} from "react";
import SignatureCanvas from "react-signature-canvas";

import "./SignatureModal.scss";

const SignatureModal = ({ open, setOpen, onSave }) => {
  const [touched, setTouched] = useState(false);
  const [canvasSizes, setCanvasSizes] = useState(null);
  const sigPad = useRef(0);
  const containerRef = useRef();

  useEffect(() => {
    function handleResize() {
      const { offsetHeight, offsetWidth } = containerRef.current;
      setCanvasSizes({
        width: offsetWidth,
        height: offsetHeight
      });
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onApply = () => {
    if (touched) {
      const img = sigPad.current.getTrimmedCanvas().toDataURL('./');
      onSave(img);
    }
  };

  const clear = () => {
    setTouched(false);
    sigPad.current.clear();
  };

  return (
    <>
      <Modal title={MESSAGES.SIGNATURE} open={open} classNames="signature-modal" onClose={() => setOpen(false)}>
        <div className="signature-modal-content">
          <div className="signature-wrapper" ref={containerRef}>
            {canvasSizes &&(
              <SignatureCanvas
                ref={sigPad}
                clearOnResize
                classNames="test"
                penColor="#04365B"
                onBegin={() => {setTouched(true)}}
                canvasProps={{
                  width: canvasSizes.width,
                  height: canvasSizes.height,
                  className: "signature-canvas",
                }}
              />
            )}
          </div>
          <div className="signature-modal-footer">
            <Button disabled={!touched} onClick={onApply}>{MESSAGES.APPLY_AND_PAY}</Button>
            <Button classNames="danger" onClick={clear}>{MESSAGES.CLEAR}</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

SignatureModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default SignatureModal;
