import {useAlert} from "react-alert";
import {useRef, useState} from "react";
import Input from "../../../../ui/Input";
import Button from "../../../../ui/Button";
import Loading from "../../../../ui/Loading";
import Captcha from "../../../../ui/Captcha";
import useOnce from "../../../../hooks/useOnce";
import {getUsDotById} from "../../../../Services";
import {Link, useNavigate} from "react-router-dom";
import Checkbox from "../../../../ui/Input/Checkbox";
import MESSAGES from "../../../../constants/messages";

import "./index.scss";

const RegistrationForm = () => {
  const ref = useRef(null);
  const alert = useAlert();
  const navigate = useNavigate();
  const [usDot, setUsDot] = useState("");
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  useOnce(() => {
    ref.current?.focus()
  });

  const onClick = () => {
    setLoading(true);
    getUsDotById(usDot)
    .then(response => {
      if (response.HasError === false) {
        navigate(`/ucr-registration/${usDot}`);
      }
    })
    .catch(err => {
      alert.show(err?.message || "error", {type: "error"});
    }).finally(() => setLoading(false));
  };

  const onChange = e => {
    const {value} = e.target;
    setUsDot(value);
  };

  return (
    <div className="ucr-registration-form loading-container">
      <Loading isLoading={loading} />
      <h4 className="title">{MESSAGES.UNIFIED_CARRIER_REGISTRATION}</h4>
      <Input
        id="us-dot-input"
        value={usDot}
        action="flat"
        innerRef={ref}
        onChange={onChange}
        classNames="full-width"
        placeholder={MESSAGES.ENTER_USDOT_TO_BEGIN}
      />
      <p className="text-center">{MESSAGES.REGISTRATION_SECTION_MODAL_TEXT}</p>
      <div>
        <Checkbox name="agree" checked={checked} label="I Agree" onChange={() => setChecked(prev => !prev)} />
      </div>
      <div className="privacy-block">
        <Link to="/privacy-and-policy" target="_blank" className="btn">{MESSAGES.PRIVACY_POLICY}</Link>
        <Link to="/terms-and-condition" target="_blank" className="btn">{MESSAGES.TERM_AND_CONDITION}</Link>
      </div>
      <Captcha onChange={setToken} />
      <Button type="solid" classNames="full-width" onClick={onClick} disabled={!checked || !usDot || !token}>{MESSAGES.SUBMIT}</Button>
    </div>
  );
};

export default RegistrationForm;
