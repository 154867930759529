import PropTypes from "prop-types";
import cx from "classnames";
import {useState, useCallback} from "react";

import "./index.scss";

const Accordion = ({ items }) => {
  const [activeId, setActiveId] = useState(null);

  const onClick = useCallback((id) => {
    setActiveId(prev => prev === id ? null : id);
  }, []);

  return (
    <div className="ucr-accordion">
      {items.map(({title, content}, key) => (
        <div className={cx("ucr-accordion-box", {active : key === activeId})} key={title}>
          <div className="ucr-accordion-title"><button onClick={() => onClick(key)}>{title}</button></div>
          <div className="ucr-accordion-content">{content}</div>
        </div>
      ))}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  )
};

export default Accordion;
