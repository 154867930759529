import Button from "../../../../ui/Button";
import MESSAGES from "../../../../constants/messages";

import "./index.scss";

const RegistrationHelpText = () => {
  return (
    <>
    <div className="registration-help-text">
      <p>{MESSAGES.REGISTRATION_HELP_TEXT}</p>
    </div>
    <div className="registration-help-text">
      <p>{MESSAGES.MSC_150.LINK_TEXT}</p>
      <a target="_blank" rel="noreferrer" href={MESSAGES.MSC_150.LINK}><Button type="solid">{MESSAGES.CLICK_HERE}</Button></a>
    </div>
   </>
  );
};

export default RegistrationHelpText;
