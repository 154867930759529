import MESSAGES from "../../../../constants/messages";

const PricingDescription = () => {
  return (
    <div className="text-block">
      {MESSAGES.ABOUT_UCR_TEXTS.SECOND_BLOCK_TEXTS.map((item, key) => (
        <p key={key}>
          {item}
        </p>
      ))}
    </div>
  );
};

export default PricingDescription;
