import RegistrationForm from "./RegistrationForm";
import RegistrationHelpText from "./RegistrationHelpText";

import "./index.scss";

const RegistrationSection = () => {
  return  (
    <div className="container-fluid registration-section">
      <div className="container">
        <div className="flex-item">
          <RegistrationHelpText />
        </div>
        <div className="flex-item">
          <RegistrationForm />
        </div>
        <div className="flex-item" />
      </div>
    </div>
  );
};

export default RegistrationSection
