import cx from "classnames";
import PropTypes from "prop-types";

import "./index.scss";

const PageTitle = ({ title, classNames, children }) => {
  return (
    <p className={cx("page-title", classNames)}>{title} {children}</p>
  );
};

PageTitle.propTypes = {
  classNames: PropTypes.string,
  title: PropTypes.string.isRequired,
};

PageTitle.propTypes = {
  classNames: ""
};

export default PageTitle;
