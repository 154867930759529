import validate from "./validate";
import PropTypes from "prop-types";
import Input from "../../ui/Input";
import {useAlert} from "react-alert";
import Loading from "../../ui/Loading";
import Radio from "../../ui/Input/Radio";
import {useEffect, useState} from "react";
import {makePayment} from "../../Services";
import Select from "../../ui/Input/Select";
import PageTitle from "../../ui/PageTitle";
import STATES from "../../constants/states";
import {useNavigate} from "react-router-dom";
import MESSAGES from "../../constants/messages";
import CreditCardInput from "react-credit-card-input";
import SignatureModal from "../../ui/Signature/SignatureModal";
import StepperFooter from "../shared/Stepper/components/StepperFooter";

import "./index.scss";

import jsb from "../../scss/img/jcb.png";
import visa from "../../scss/img/visa.png";
import master from "../../scss/img/masrercard.png";
import discover from "../../scss/img/discover-logo.png";
import express from "../../scss/img/american-express.png";
import {formatNumberToShow} from "../../utils";

const PaymentInfo = ({onPrev, id, state, addresses, registrationDetails, carrierInfo}) => {
  const years = carrierInfo.RegistrationYear;
  const alert = useAlert();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("new");
  const [errors, setErrors] = useState({hasError: false, errors: {}});
  const [data, setData] = useState({
    zip: "",
    city: "",
    address: "",
    cardCode: "",
    lastName: "",
    firstName: "",
    cardNumber: "",
    expirationDate: "",
    state: STATES[0].state,
    ...state
  });

  const onChange = (e, key) => {
    setData(prev => ({
      ...prev,
      [key]: e.target.value
    }));
  };

  useEffect(() => {
    if(errors.hasError) {
      setErrors(validate(data));
    }
  }, [data, errors.hasError]);

  const onChangeAddress = (address) => {
    setAddress(address);
    if (address === "new") {
      return;
    }

    const isPhysical = address === "physical";
    setData(prev => ({
      ...prev,
      city: addresses[isPhysical ? "phCity" : "mailingCity"],
      state: addresses[isPhysical ? "phState" : "mailingState"],
      zip: addresses[isPhysical ? "phZipCode" : "mailingZipCode"],
      address: addresses[isPhysical ? "phStreet" : "mailingStreet"],
    }));
  };

  const onClickNext = () => {
    const res = validate(data);
    if (res.hasError) {
      setErrors(res);
      return;
    }
    setOpen(true);
  };

  const onSave = (signature) => {
    setOpen(false);
    setLoading(true);

    const arr = data.expirationDate.split("/");
    const d = {
      signature,
      zip: data.zip,
      city: data.city,
      state: data.state,
      address: data.address,
      cardCode: data.cardCode,
      lastName: data.lastName,
      firstName: data.firstName,
      cardNumber: data.cardNumber.replaceAll(" ", ""),
      expirationDate: `20${arr[1].trim()}-${arr[0].trim()}`,
    };

    makePayment(id, d)
      .then(response => {
        if (response.hasError) {
          response.errors.map(({ errorMessage }) => alert.show(errorMessage, {type: "error"}));
        } else {
          navigate("/success-page");
        }
      })
      .catch(err => {
        alert.show(err.message || err, {type: "error"});
      })
      .finally(() => setLoading(false));
  };

  const disabledAddress = address !== "new";
  return (
    <div className="payment-info">
      <div className="payment-logos">
        {MESSAGES.PAY_WITH_CREDIT_CARD}
        <div><img src={visa} alt="visa" /></div>
        <div><img src={master} alt="master" /></div>
        <div><img src={discover} alt="discover" /></div>
        <div><img src={jsb} alt="jsb" /></div>
        <div><img src={express} alt="american express" /></div>
      </div>
      <CreditCardInput
        cardNumberInputProps={{
          value: data.cardNumber,
          onChange: e => onChange(e, "cardNumber")
        }}
        cardExpiryInputProps={{ value: data.expirationDate, onChange: e => onChange(e, "expirationDate") }}
        cardCVCInputProps={{ value: data.cardCode, onChange: e => onChange(e, "cardCode") }}
        fieldClassName="input"
      />
      <div className="name-info">
        <Input
          required
          label="First Name"
          value={data.firstName}
          error={errors.errors.firstName}
          onChange={e => onChange(e, "firstName")}
        />
        <Input
          required
          label="Last Name"
          value={data.lastName}
          error={errors.errors.lastName}
          onChange={e => onChange(e, "lastName")}
        />
      </div>
      <p className="group-input-label">{MESSAGES.BILLING_ADDRESS}</p>
      <div className="radio-block">
        <Radio
          value="new"
          name="address"
          checked={address === "new"}
          label={MESSAGES.NEW_ADDRESS}
          onChange={() => onChangeAddress("new")}
        />
        <Radio
          name="address"
          value="physical"
          checked={address === "physical"}
          onChange={() => onChangeAddress("physical")}
          label={MESSAGES.SAME_AS_PHYSICAL_ADDRESS}
        />
        <Radio
          name="address"
          value="mailing"
          checked={address === "mailing"}
          onChange={() => onChangeAddress("mailing")}
          label={MESSAGES.SAME_AS_MAILING_ADDRESS}
        />
      </div>
      <div className="address-row">
        <Input
          required
          value={data.address}
          label={MESSAGES.ADDRESS}
          disabled={disabledAddress}
          error={errors.errors.address}
          placeholder={MESSAGES.ADDRESS}
          onChange={e => onChange(e, "address")}
        />
        <Input
          required
          value={data.city}
          label={MESSAGES.CITY}
          disabled={disabledAddress}
          error={errors.errors.city}
          placeholder={MESSAGES.CITY}
          onChange={e => onChange(e, "city")}
        />
        <Select
          required
          value={data.state}
          label={MESSAGES.STATE}
          disabled={disabledAddress}
          onChange={e => onChange(e, "state")}
        >
          {STATES.map(m => (
            <option value={m.state} key={m.name}>{m.state} {m.name}</option>
          ))}
        </Select>
        <Input
          required
          value={data.zip}
          error={errors.errors.zip}
          label={MESSAGES.ZIP_CODE}
          disabled={disabledAddress}
          placeholder={MESSAGES.ZIP_CODE}
          onChange={e => onChange(e, "zip")}
        />
      </div>
      <div className="order-details">
        <PageTitle title="Order Details" />
        <div className="total-row-wrapper">
          <div className="total-row">
            <label>{MESSAGES.VEHICLE_TOTAL}</label>
            <span>{registrationDetails.totalVehiclesCount}</span>
          </div>
          <div className="total-row total-cost">
            <label>{MESSAGES.TOTAL_COST_FOR_YEAR}</label>
            <span>${formatNumberToShow(registrationDetails.costForOneYear)}.00</span>
          </div>
        </div>
        <div className="total-row-wrapper">
          <div className="total-row">
            <label>{MESSAGES.YEARS}</label>
            <span>{years.join(",")}</span>
          </div>
          <div className="total-row total-cost">
            <label>{MESSAGES.TOTAL_COST}</label>
            <span>${formatNumberToShow(registrationDetails.totalCost)}.00</span>
          </div>
        </div>
      </div>
      {open && (
        <SignatureModal
          open={open}
          onSave={onSave}
          setOpen={setOpen}
        />
      )}
      <StepperFooter
        successText="Next"
        classNames="danger"
        onClickPrevious={onPrev}
        disabledNext={errors.hasError}
        onClickNext={onClickNext}
      />
      <Loading isLoading={loading} />
    </div>
  );
};

PaymentInfo.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  saveState: PropTypes.func.isRequired,
  addresses: PropTypes.object.isRequired,
  registrationDetails: PropTypes.object.isRequired,
};

export default PaymentInfo;
