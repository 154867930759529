import Icon from "../Icon";
import cx from "classnames";
import PropTypes from "prop-types";

import "./index.scss";

const Step = ({ title, description, active }) => {
  return (
    <div className="ucr-step-block">
      <div className={cx("ucr-step", {active})}>
        <div className="ucr-step-icon">
          <Icon type="BsFillCheckCircleFill" />
        </div>
        <div className="ucr-step-text-block">
          <div className="ucr-step-title">{title}</div>
          <div className="ucr-step-description">{description}</div>
        </div>
      </div>
    </div>
  );
};

Step.propTypes = {
  description: PropTypes.string,
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

Step.defaultProps = {
  description: ""
};

export default Step;
