import Map from "../../../ui/Map";
import PageTitle from "../../../ui/PageTitle";
import MESSAGES from "../../../constants/messages";
import IconWithText from "../../../ui/IconWithText";
import TextWithTitle from "../../../ui/TextWithTitle";
import PriceList from "../../AboutRegistration/Pricing/PriceList";

import "./index.scss";
import starLight from "../../../scss/icons/star-light.png";
import starDark from "../../../scss/icons/start-dark.png";

const MapSection = () => {

  return (
    <div className="container-fluid map-container-section">
      <div className="container">
        <PageTitle title={MESSAGES.MAP_SECTION_TITLE} />
        <div className="flex">
          <div className="map-block">
            <Map />
          </div>
          <div>
            <IconWithText img={starDark}>{MESSAGES.PARTICIPATING_STATES}</IconWithText>
            <IconWithText img={starLight}>{MESSAGES.NON_PARTICIPATING_STATES}</IconWithText>
          </div>
        </div>
        <div className="flex form-block flex-end">
          <div className="text-column">
            {MESSAGES.MAP_SECTION_TEXTS.map(({TEXT, TITLE}) => (
              <TextWithTitle key={TITLE} text={TEXT} title={TITLE} />
            ))}
          </div>
          <div className="form-column">
            <PriceList focusElementId="us-dot-input" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default MapSection;
