import PropTypes from "prop-types";
import Button from "../../../ui/Button";

import "./index.scss";

const ModalContent = ({ onChange, onClose }) => {
  const handleClick = value => {
    onChange({target: {value}});
    onClose(false);
  };

  return (
    <div className="option-modal-content">
      <p>Using option B will require you to include ALL CMV's that you owned or operated during the 12-month period from 7/1/2021 to 6/30/2022.
        This could result in a higher fee than may be required when using ontion A</p>
      <p>Choosing Option B may subject your registration to additional review and audit You are required to maintain a list of the vehicles entered under this option. as of the time of registration. which must be provided to your base state upon request.</p>
      <h3>UCR-2 FORM</h3>
      <p className="center">Continue Using</p>
      <div className="option-modal-footer">
        <Button onClick={() => handleClick("optionA")}>Option A</Button>
        <Button onClick={() => handleClick("optionB")}>Option B</Button>
      </div>
    </div>
  );
};

ModalContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalContent;
