import PageTitle from "../../../ui/PageTitle";
import MESSAGES from "../../../constants/messages";

import "./index.scss"

const AboutUcrRegistration = () => {
  return (
    <div className="container-fluid about-ucr-registration">
      <div className="container">
        <PageTitle title={MESSAGES.ABOUT} />
        <div className="flex with-background flex-end">
          <div className="text-block light">
            {MESSAGES.ABOUT_UCR_TEXTS.FIRST_BLOCK_TEXTS.map((item, key) => (
              <p key={key}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUcrRegistration;
