import TextTitle from "../../ui/TextTitle";
import IconWithInfo from "../../ui/IconWithText";

import "./index.scss";

import MESSAGES from "../../constants/messages";

const Footer = () => {
  return (
    <footer className="footer container-fluid">
      <div className="container">
        <div className="flex-item">
          <TextTitle title={MESSAGES.CONTACT_US} />
          <IconWithInfo icon="BsTelephone">
            <a href={MESSAGES.CONTACT_INFO.PHONE}>{MESSAGES.CONTACT_INFO.PHONE_TEXT}</a>
          </IconWithInfo>
          <IconWithInfo icon="BsEnvelope">
            <a href={MESSAGES.CONTACT_INFO.EMAIL_TO}>
              {MESSAGES.CONTACT_INFO.EMAIL}
            </a>
          </IconWithInfo>
          <IconWithInfo icon="BsGlobe">
            <a target="_blank" rel="noreferrer" href={MESSAGES.CONTACT_INFO.WEB_PAGE}>
              {MESSAGES.CONTACT_INFO.WEB_PAGE_TEXT}
            </a>
          </IconWithInfo>
        </div>
        <div className="flex-item about-ucr">
          <TextTitle title={MESSAGES.ABOUT} />
          <p>{MESSAGES.ABOUT_UCR_INFO}</p>
        </div>
        <div className="flex-item quick-links">
          <TextTitle title={MESSAGES.QUICK_LINKS} />
          <ul>
            {MESSAGES.QUICK_LINKS_CONTENT.map((i, k) => (
              <li key={k}>
                <a href={i.LINK}>{i.NAME}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
