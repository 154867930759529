import Icon from "../Icon";

import "./index.scss";

const IconWithInfo = ({ icon, img, children, ...rest }) => {
  return (
    <span className="icon-with-info" {...rest}>
      {img
       ? <img src={img} alt={img} />
       : <Icon className="icon" type={icon} />
      }
      <span className="info">
        {children}
      </span>
    </span>
  );
};

export default IconWithInfo;
