export const PRICING_LIST = [
  {
    from: 0,
    to: 2,
    name: "Trucks",
    price: "125"
  },
  {
    from: 3,
    to: 5,
    name: "Trucks",
    price: "275"
  },
  {
    from: 6,
    to: 20,
    name: "Trucks",
    price: "485"
  },
  {
    from: 21,
    to: 100,
    name: "Trucks",
    price: "1645"
  },
  {
    from: 101,
    to: 1000,
    name: "Trucks",
    price: "7445"
  },
  {
    from: 1001,
    to: Infinity,
    name: "Trucks",
    price: "Contact us directly.",
    isLast: true
  }
];
