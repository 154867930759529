import CarrierInfo from "../../CarrierInfo";
import PaymentInfo from "../../PaymentInfo";
import AddressDetails from "../../AddressDetails";
import MESSAGES from "../../../constants/messages";
import RegistrationDetails from "../../RegistrationDetails";

export const STEPS = [
  {
    key: "addressDetails",
    title: MESSAGES.ADDRESS_DETAILS,
    Component: AddressDetails
  },
  {
    key: "carrierInfo",
    title: MESSAGES.CARRIER_INFO,
    description:MESSAGES.CONFIRM_YOUR_OFFICIAL_REPRESENTATIVE,
    Component: CarrierInfo,
  },
  {
    key: "registrationDetails",
    title: MESSAGES.REGISTRATION_DETAILS,
    description:MESSAGES.NUMBER_OF_REGISTERED_VEHICLES,
    Component: RegistrationDetails
  },
  {
    key: "paymentInfo",
    title: MESSAGES.PAYMENT_INFO,
    description:MESSAGES.PAY_TO_COMPLETE_REGISTRATION,
    Component: PaymentInfo
  }
];

export const defaultStates = Object.values(STEPS).reduce((acc, curr) => {
  return {
    ...acc,
    [curr.key]: {}
  };
}, {});
