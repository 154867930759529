import Button from "../../../../../ui/Button";
import MESSAGES from "../../../../../constants/messages";

import "./index.scss";

const StepperFooter = ({onClickPrevious, onClickNext, successText, disabledNext, classNames, hideNext }) => {

  return (
    <div className="stepper-footer">
      {onClickPrevious &&
        <Button
          type="solid"
          classNames="previous"
          onClick={onClickPrevious}
        >
          {MESSAGES.PREVIOUS}
        </Button>
      }
      {!hideNext &&
        <Button
          type="solid"
          disabled={disabledNext}
          onClick={onClickNext}
          classNames={classNames}
        >
          {successText || MESSAGES.NEXT}
        </Button>
      }
    </div>
  );
};

export default StepperFooter;
