import PropTypes from "prop-types";
import Button from "../../../../ui/Button";
import {useNavigate} from "react-router-dom";
import {PRICING_LIST} from "../../../../constants";
import MESSAGES from "../../../../constants/messages";

import "./index.scss";
import {formatNumberToShow} from "../../../../utils";

const PriceList = ({ focusElementId }) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (focusElementId) {
      const elem = document.getElementById(focusElementId);
      elem.focus();
      return;
    }

    navigate("/");
  };

  return (
    <div className="pricing-block">
      <div className="pricing-table">
        <div className="pricing-title">
          {MESSAGES.CUSTOMER_PRICE_PLAN}
        </div>
        <div className="price-list">
          {PRICING_LIST.map(({from, to, name, price, isLast}) => (
            <div className="price-list-item" key={price}>
              <div>{from}{isLast ? "+" : `-${to}`} {name}</div>
              <div className="price">
                {isLast
                 ? <a href={MESSAGES.CONTACT_INFO.PHONE}>{price}</a>
                 : `$${formatNumberToShow(price)}.00`
                }
              </div>
            </div>
          ))}
        </div>
        <div className="price-footer">
          <Button
            type="solid"
            classNames="danger"
            onClick={onClick}
          >
            {MESSAGES.REGISTER_NOW}
          </Button>
        </div>
      </div>
    </div>
  );
};

PriceList.propTypes = {
  focusElementId: PropTypes.string
};

export default PriceList;
