import * as bs from "react-icons/bs";

const Icons = {
  ...bs
};
const Icon = ({ type, className }) => {
  const Component = Icons[type];
  return <span className={className}><Component className={className} /></span>;
};

export default Icon;
