const API = {
  handleResponse: response => {
    if(response.status === 200) {
      return response.json();
    }

    throw new Error("SOMETHING WENT WRONG");
  },

  GET: (url) => fetch(url, {
    Method: "GET",
    Headers: {
      Accept: "application.json",
      "Content-Type": "application/json"
    },
    Cache: "default"
  }).then(API.handleResponse),

  POST: (url, params) => fetch(url, {
    method: "POST",
    headers: {
      "Accept": "application.json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  }).then(API.handleResponse),

  PUT: (url, params) => fetch(url, {
    method: "PUT",
    headers: {
      "Accept": "application.json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  }).then(API.handleResponse),
};

export default API;
