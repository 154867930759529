import InfoPages from "../index";
import PageTitle from "../../../ui/PageTitle";
import MESSAGES from "../../../constants/messages";

const PrivacyAndPolicy = () => {
  return (
    <InfoPages> <PageTitle title={MESSAGES.PRIVACY_POLICY} />
      <p>This privacy notice explains the privacy practices of <a href="http://ucr-us.com" className="important">ucr-us.com</a></p>
      <p>The statement provided below only applies to the information collected on this website. Please read the following conditions carefully as you review our current privacy policy.</p>
      <h3>Collection, Use, and Sharing of Information</h3>
      <p><a href="http://ucr-us.com" className="important">ucr-us.com</a> is the sole owner of the information collected on the website. To provide our customers with assistance in filing and other services, we must gather certain personal information from customers.</p>
      <p>This information is only used to accurately provide the services requested by our customers. We do not sell or share this information with any third parties without the customer's consent. We ensure the security of the information collected through our website and do not store or retain it, it is deleted from our system once it has been used for its intended purpose.</p>
      <h3>Financial Data</h3>
      <p>Customers' financial information, such as credit and debit card information, will be collected through email, phone, fax, or our online automated system using Authorize.Net, a widely used and secure payment gateway. The information collected is highly encrypted and protected. This is how we will obtain customers' payments for services. Additionally, we do not store or retain payment information, all credit card processing methods are in compliance with credit card processing security standards.</p>
      <h3>Website Safety</h3>
      <p>We are committed to protecting the privacy of our customers, including through our website. To ensure the security of our website is up-to-date, we have a team of professionals who manage it. Our developers regularly install updates to ensure the security of <a href="http://ucr-us.com" className="important">ucr-us.com</a> is as high as possible.</p>
      <p>Our website uses SSL encryption to protect any personal information provided on the website. The information entered on the website is only visible to the customer and the company's authorized systems.</p>
      <p>In order to maintain the accuracy of the information on the website, our team may change the content at any time without notice. Furthermore, we reserve the right to revise our Privacy Policy and Terms & Conditions without notice. The use of information on websites without permission is prohibited.</p>
      <p>If you have any concerns or questions about the information provided on our website or our Privacy Policy, please contact us through email or phone without hesitation.</p>
    </InfoPages>
  );
};

export default PrivacyAndPolicy;
