import cx from "classnames";
import {useState} from "react";
import Icon from "../../ui/Icon";
import APP_ROUTES from "../../routes";
import DropDown from "../../ui/DropDown";
import MESSAGES from "../../constants/messages";
import {Link, useLocation} from "react-router-dom";

import "./index.scss";
import logo from "./../../scss/img/ucr-logo.png";

const Header = () => {
  const [open, setOpen] = useState(false);
  const {pathname} = useLocation();

  return (
    <header className="header container-fluid">
      <div className="container">
        <div className="nav-bar">
          <ul className={cx({open})}>
            {APP_ROUTES
            .filter(({hideMenu}) => !hideMenu)
            .map(({ path, title, logoSrc }) => (
              <li key={path} className={cx({active: pathname === path})}>
                <Link to={path} onClick={() => setOpen(null)}>
                  {logoSrc ? <img src={logoSrc} alt={title} /> : title}
                </Link>
              </li>
            ))}
            <li>
              <DropDown />
            </li>
            <li className={cx({active: pathname === "/contact-us"})}>
              <Link to="/contact-us" onClick={() => setOpen(null)}>
                {MESSAGES.CONTACT_US}
              </Link>
            </li>
          </ul>
        </div>
        <Link to="/" className="logo-mobile mobile">
          <img src={logo} alt="ucr" />
        </Link>
        <button
          className="toggle mobile"
          onClick={() => setOpen(prev => !prev)}
        >
          <Icon type={open ? "BsXLg" : "BsList"} />
        </button>
      </div>
    </header>
  );
};

export default Header;
