import REG_EXP from "../../constants/regExp";

const validate = (data) => {
  const errors = {
    terms: !data.terms,
    checked: !data.checked,
    FullName: !data.FullName,
    MobilePhone: !data.MobilePhone,
    BusinessPhone: !data.BusinessPhone,
    Email: !(data.Email && REG_EXP.EMAIL.test(data.Email)),
  };

  return {
    errors,
    hasError: Object.values(errors).some(r => r)
  };
};

export default validate
