const validate = (data) => {
  const errors = {
    mailingCity: !data.mailingCity,
    mailingStreet: !data.mailingStreet,
    mailingZipCode: !data.mailingZipCode,
  };

  return {
    errors,
    hasError: Object.values(errors).some(r => r)
  };

};

export default validate
