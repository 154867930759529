import cx from "classnames";
import PropTypes from "prop-types";
import ModalTitle from "./ModalTitle";

import "./index.scss";

const Modal = ({ open, title, onClose, classNames, children }) => {
  return !open ? null : (
    <div className={cx("ucr-modal-wrapper", classNames)}>
      <div className="ucr-modal">
        <ModalTitle onClose={onClose} title={title} />
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  classNames: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default Modal;
