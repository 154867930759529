const validate = (data) => {
  const errors = {
    zip: !data.zip,
    city: !data.city,
    state: !data.state,
    address: !data.address,
    lastName: !data.lastName,
    firstName: !data.firstName,
    cardCode: !(data.cardCode && data.cardCode.length > 2),
    cardNumber: !(data.cardNumber && data.cardNumber.length > 11),
    expirationDate: !(data.expirationDate && data.expirationDate.length > 4),
  };

  return {
    errors,
    hasError: Object.values(errors).some(r => r)
  };

};

export default validate
