import cx from "classnames";
import PropTypes from "prop-types";

import "./checkbox.scss";

const Checkbox = ({ classNames, name, action, required, label, disabled, onChange, checked, error, ...rest}) => {
  return (
    <div className={cx("checkbox-field", {disabled})}>
      <input checked={checked} type="checkbox" name={name} disabled={disabled} className={cx("checkbox", `${classNames} ${action}`, {disabled, error})} onChange={onChange} {...rest} />
      {label && <label>{required && <span className="required-field">*</span>}{label}</label>}
    </div>
  );
};

Checkbox.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  action: PropTypes.oneOf([
    "flat",
    "outline"
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  error: PropTypes.bool,
};

Checkbox.defaultProps = {
  action: "outline",
  required: false,
  disabled: false,
  error: false
};

export default Checkbox;
