import "./index.scss";

const Loading = ({isLoading}) => {
  return isLoading
   ? (
      <div className="loading">
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
   : null
};

export default Loading;
