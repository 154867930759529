const DATA = [
  {
    title: "Motor Carrier",
    content: "A business that provides transportation for hire. They transport property or passengers by motor vehicle"
  },
  {
    title: "Motor Private Carrier",
    content: "This type of business provides transportation of property or passengers by motor vehicle but does not offer their services for hire. They work within their own business."
  },
  {
    title: "Freight Forwarder",
    content: "Business that is not a motor carrier but organizes motor carrier shipments to get goods from the manufacturer or producer to a market, customer."
  },
  {
    title: "Broker",
    content: "Business that provides or arranges for transportation by a motor carrier. Often a third party. Brokers do not operate motor vehicles of their own"
  },
  {
    title: "Leasing Company",
    content: "This business leases or rents motor vehicles to a motor carrier, motor private carrier, or freight forwarder. They do not operate its own vehicles."
  },
];

export default DATA;
