import PropTypes from "prop-types";
import cx from "classnames";

import "./index.scss"

const Button = ({ type, classNames, disabled, children, ...rest }) => {
  return (
    <button
      className={cx("btn", `${type} ${classNames}`, {"disabled": disabled})} disabled={disabled} {...rest}>
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf([
    "outline",
    "solid",
    "flat",
    "link"
  ]),
  classNames: PropTypes.string,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  type: "solid",
  classNames: "",
  disabled: false
};

export default Button;
